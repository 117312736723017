export const DBConfig = {
    name: 'ImageCollectDb',
    version: 1,
    objectStoresMeta: [
      {
        store: 'collect',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'cod', keypath: 'cod', options: { unique: false } },
          { name: 'item', keypath: 'item', options: { unique: false } }
        ]
      },
      {
        store: 'images',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'collectId', keypath: 'collectId', options: { unique: false } },
          { name: 'item', keypath: 'item', options: { unique: false } }
        ]
      }
    ]
  };