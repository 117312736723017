import AsyncStorage from '@react-native-async-storage/async-storage';
import { createDrawerNavigator } from '@react-navigation/drawer';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Button, TextInput, Title } from 'react-native-paper';
import api from '../services/data-service';
import ApiConfig from '../services/shared';
import { TextInput as Input } from 'react-native-paper';
import base from '../constants/base';
import { AuthContext } from '../context/PreferencesContext';


interface ILoginProps {
  handleLogIn: (arg0: boolean) => void;
}

const LoginPage: React.FunctionComponent<ILoginProps> = (props) => {

  const Drawer = createDrawerNavigator();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const { setUser } = React.useContext(AuthContext);

  const postLogin = () => {

    api.post("/v2/authenticate", { guidUrl: ApiConfig.guidUrl, username: name, password: password }).then(async (response) => {

      switch (response.status) {
        case 200:
          if (response.data?.token) {
            await AsyncStorage.setItem('@access_token', response.data?.token);
            await AsyncStorage.setItem('@user', JSON.stringify(response.data?.user));
            if(props.handleLogIn)
              props.handleLogIn(true);
            
              setUser(response.data?.user);
          }
          break;

        default:
          break;
      }
      console.log(response);

    }).catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    });

  }


  return (
    <View style={base.centered}>

      <Title>Login</Title>

      <Input style={base.input}
        placeholder="Usuário"
        right={<TextInput.Icon name="account" />}
        onChangeText={(value) => setName(value)}
      />

      <Input style={base.input} secureTextEntry={true}
        placeholder="Senha"  textContentType='password'
        right={<TextInput.Icon name="key" />}
        onChangeText={(value) => setPassword(value)}
      />

      <Button icon="login-variant" mode="contained" onPress={() => postLogin()} >
        Entrar
      </Button>

    </View>
  );
};

export default LoginPage;
