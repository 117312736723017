import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  centered: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  input: {
    marginBottom: 15
  },

  headerpage: {
    padding: 20,
    paddingBottom: 0
  },

  bodyContainner: {
    flex: 1,
    padding: 20,
  },

  cardItem: {
    marginBottom: 12,
    //minHeight: 70
    
  },

  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
  },

  fabLeft: {
    position: 'absolute',
    margin: 16,
    left: 0,
    bottom: 0,
  },

});
