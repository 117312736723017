
export class AtendimentoLocal {

    dataEmissao: any;
    idOS: number | null;
    idUnidade: number | null;

    validado: boolean | null;
    imagensColetadas: boolean | null;
    enviado: boolean | null;

    dataConclusao: string | null;
    concluido: boolean | null;

    nroOS: string;
    nomeCliente: string;

    equipamento: string;
    info: any;
    tipoAt: string;
}


export class ImageData {
    id: string;
    imgType: any;
    base64Image: string;
    subject: string;
    sendDate: any;
}

export interface ImageDataType {
    before: 0;
    after: 1;
    rat: 2;
}