import { CommonActions } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Platform, StyleSheet, View, SafeAreaView, ScrollView, ActivityIndicator, FlatList } from 'react-native';
import { Appbar, Button, Caption, Card, Colors, Divider, FAB, IconButton, List, RadioButton, TextInput } from 'react-native-paper';
import base from '../constants/base';
import { AtendimentoLocal, ImageData } from '../models/models';
import styled from 'styled-components';
import { useIndexedDB } from 'react-indexed-db';
import moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

class CollectModalComp extends React.Component<{}, any> {

  colors;
  navigation;
  _userInfo;

  db = useIndexedDB('collect');
  imageDb = useIndexedDB('images');

  collectId = null;
  nroOS = '';
  _unsubscribe;





  constructor(props) {
    super(props);

    this.state = {

      isLoading: false,


      item: {},
      images: [],

      modeSetOs: 'manual',
      osDefinida: null,

      showImage: false,
      image: undefined,
      image_item: undefined,
      allImagesCollected: false,
    };

    this.loadCollect = this.loadCollect.bind(this);
    this.loadImages = this.loadImages.bind(this);
    this.close = this.close.bind(this);

    this.navigation = props.navigation;
    this.collectId = props.collectId;
    this._userInfo = props.userInfo;
    this.colors = props.colors;


  }



  componentDidMount() {



    this.loadCollect();


    this._unsubscribe = this.navigation.addListener('focus', () => {
      console.log('CollectModal Focused ...');

      let item = this.state.item as AtendimentoLocal;

      var selectedOS = JSON.parse(sessionStorage.getItem('@selectedOS') || '{}');
      if (item && selectedOS?.idOs) {
        item.idUnidade = selectedOS.idUnidade;
        item.idOS = selectedOS.idOs;
        item.nroOS = selectedOS.cod;
        item.nomeCliente = selectedOS.nomeCliente;
        item.equipamento = selectedOS.equipamento;
        item.info = selectedOS.info;

        this.setState({ item: item, osDefinida: true });

        sessionStorage.removeItem('@selectedOS');
      }


      var currentImage = sessionStorage.getItem('@currentImage');
      if (currentImage) {

        sessionStorage.removeItem('@currentImage');

        var img: ImageData = JSON.parse(currentImage) as ImageData;
        this.imageDb.add({ collectId: this.collectId, item: img }).then(() => {
          this.loadImages();
        });


      }

    });

  }

  componentWillUnmount() {
    this._unsubscribe();

  }

  close() {
    this.navigation.dispatch(CommonActions.goBack());
  }

  loadCollect() {

    if (this.collectId) {
      this.setState({ isLoading: true });

      this.db.getByID(this.collectId).then(resp => {
        this.setState({
          item: resp.item,
          osDefinida: (resp.item.nroOS || resp.item.idOS) ? true : false,
        });

        this.loadImages();
        this.setState({ isLoading: false });
      });

    } else {

      var newItem = new AtendimentoLocal();
      newItem.dataEmissao = moment();

      var it = JSON.parse(JSON.stringify(newItem));
      this.db.add({ item: it }).then(id => {
        this.setState({
          item: it,
          osDefinida: false,
        });
        this.collectId = id;
      });

    }

  }


  loadImages = () => {
    useIndexedDB('images').getAll().then(resp => {
      var imgs = resp?.filter(i => i.collectId == this.collectId);

      var allImagesCollected = imgs.find(i => i.item.imgType == 0) != null
        && imgs.find(i => i.item.imgType == 1) != null
        && imgs.find(i => i.item.imgType == 2) != null;

      this.setState({ images: imgs, allImagesCollected: allImagesCollected });
    })
  }

  deleteImage = async (imgItem) => {

    let resp = await MySwal.fire({
      title: 'Excluir Imagem?',
      html: 'Confirma a exclusão dessa imagem?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sim, Excluir',
      denyButtonText: `Não`,
    }).then();

    if (resp.isConfirmed) {

      await useIndexedDB('images').deleteRecord(imgItem.id).then();
      this.loadImages();
      this.setState({ showImage: false });
      
    } else if (resp.isDenied) {
      
    }

    
   
  };


  render() {

    const AppbarHeader = () => {

      return (<Appbar.Header>

        <Appbar.BackAction onPress={() => {

          let item = this.state.item as AtendimentoLocal;
          if (!item.nroOS && this.state.images.length <= 0) {
            this.db.deleteRecord(this.collectId);
          } else {
            saveItem();
          }

          this.navigation.goBack();
        }} />


        <Appbar.Content title="" subtitle={(this.state.item.nroOS ? ('O.S.: ' + this.state.item.nroOS) : 'Novo Atendimento')} />
        {/* <Appbar.Action icon="reload" onPress={() => { }} />
      <Appbar.Action icon="checkbox-marked-circle-outline" onPress={() => { }} /> */}
      </Appbar.Header>)
    }



    const OsInfo = () => {

      const Info = this.state.item.info ? JSON.parse(this.state.item.info) : [];
      const data = this.state.item;

      return (
        this.state.item.idOS ?


        <List.Item
        title=""
        description={() =>
          <div>
            <Caption style={{fontWeight: 'bold'}}>{data.nomeCliente}</Caption>
            <br></br>
            <Caption style={{fontWeight: 'bold'}}>{data.equipamento}</Caption>

            <FlatList
              data={Info}
              renderItem={({ item }) => (

                <Caption><span><b>{item.Key}:</b> {item.Value}</span></Caption>
              )}
            />


          </div>
        }
        left={props => (
          <List.Icon {...props} icon={() => (
            <MaterialCommunityIcons
              name={(data.validado ? 'database-check' : 'database')}
              style={{ color: data.enviado ? this.colors.primary : this.colors.text }}
              size={24} />)} />
        )}
      />

     

          // <Card style={{ marginBottom: 12 }}>
          //   <Card.Title title={'O.S.: ' + this.state.item.nroOS} />

          //   <Card.Content>
          //     <Caption style={{ fontWeight: 'bold' }}>{this.state.item.nomeCliente}</Caption>
          //     <Caption>{this.state.item.equipamento}</Caption>

          //     <FlatList
          //       data={Info}
          //       renderItem={({ item }) => (
          
          //         <Caption><span><b>{item.Key}:</b> {item.Value}</span></Caption>
          //       )}
          //     />

          //   </Card.Content>
          // </Card>

          :

          <Card style={{ marginBottom: 12, backgroundColor: '#ffdbdb' }}>
            <Card.Title title={'O.S. ' + this.state.item.nroOS}
              right={(props) =>
                <IconButton {...props} icon="file-search-outline" onPress={() => {
                  this.navigation.navigate('OsPageModal', { findMode: true })
                }} />
              }>
            </Card.Title>

            <Card.Content>
              <Caption>Falta Selecionar a O.S. On-line!</Caption>
            </Card.Content>


            {/* <List.Item
                title="Falta Selecionar a O.S. On-line!"

                left={props => <List.Icon {...props} icon="information-outline" />}
              /> */}

          </Card>

      )
    };


    const renderImage = ({ item }) => (

      <ImageContainner style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 15
      }}>
        <ImagePreview
          image={item.item.base64Image}
          onClick={() => {
            this.setState({ image: item.item.base64Image, image_item: item})
            this.setState({ showImage: true });

          }}
        />

      </ImageContainner>



      // <img src={item.base64Image} alt={"snap"} style={{ marginTop: 15, height: 80 }}></img>
    );

    const saveItem = async () => {

      if (!this.collectId) {
        var id = await this.db.add({ item: this.state.item }).then();
        this.collectId = id;
      } else {

        let totalSendded = this.state.images.filter(i => i.item.sendDate).length;

        let item = this.state.item as AtendimentoLocal;
        item.imagensColetadas = this.state.allImagesCollected;
        item.validado = item.idOS && this.state.allImagesCollected;
        item.enviado = item.validado && (totalSendded >= this.state.images.length);
        await this.db.update({ id: this.collectId, item: item });
      }


    };


    if (this.state.osDefinida == null) {
      return (
        <View style={base.centered}>
          <ActivityIndicator animating={true} color={Colors.amber700} />
        </View>
      );
    }


    if (!this.state.osDefinida) {
      return (
        <SafeAreaView style={base.container}>

          <AppbarHeader></AppbarHeader>

          <View style={base.headerpage}>
            <RadioButton.Group onValueChange={value => this.setState({ modeSetOs: value })} value={this.state.modeSetOs}>
              <RadioButton.Item label="Digitar manualmente (off-line)" value="manual" />
              <Divider></Divider>
              <RadioButton.Item label="Buscar O.S. aberta (on-line)" value="online" />
              <Divider style={{ marginBottom: 20 }}></Divider>
            </RadioButton.Group>
            {
              this.state.modeSetOs == 'online' ?
                (
                  <Button icon="file-find-outline" mode='contained' onPress={() => this.navigation.navigate('OsPageModal', { findMode: true })}>
                    Buscar O.S.
                  </Button>
                ) :
                (
                  <View>

                    <TextInput style={base.input} mode='flat'
                      placeholder="Nº da O.S."
                      onChangeText={(value) => { this.nroOS = value }}
                    />

                    <Button icon="skip-next" mode='contained' onPress={() => {

                      if (this.nroOS) {
                        let item = this.state.item as AtendimentoLocal;
                        item.nroOS = this.nroOS;

                        this.setState({ osDefinida: true, item: item });
                      }


                    }}>
                      Continuar
                    </Button>
                  </View>




                )

            }
          </View>
        </SafeAreaView>
      )
    }


    return (
      <SafeAreaView style={base.container}>

        <AppbarHeader></AppbarHeader>

        {
          this.state.showImage ?
            <FullScreenImagePreview style={{ height: '100%' }}
              image={this.state.image}
              onClick={() => {
                this.setState({ showImage: false });
              }}
            >
              <FAB icon="keyboard-backspace" style={base.fabLeft} onPress={() => {
                this.setState({ showImage: false });
              }} />
              
              {(this.state.image_item && !this.state.image_item.item.sendDate ?
                 
                <FAB icon="trash-can-outline" style={[base.fab, {backgroundColor: 'red'}]}  
                  onPress={() => this.deleteImage(this.state.image_item)} />
                : null
                
              )}
             


            </FullScreenImagePreview> : null
        }


        {/* Use a light status bar on iOS to account for the black space above the modal */}
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />




        <View style={base.bodyContainner}>



          {/* <Portal>
                  <Modal visible={showImage} onDismiss={() => { setShowImage(false) }} contentContainerStyle={containerStyle}>
  
                  </Modal>
                </Portal> */}

          <Divider></Divider>
          <OsInfo></OsInfo>
          <Divider style={{marginBottom: 15}}></Divider>




          <ScrollView >


            <View>
              <Card>
                <Card.Title title="Foto Antes" right={(props) => <IconButton {...props} icon="camera" onPress={() => {
                  this.navigation.navigate('NativeCameraScreen', { imageType: 0 });
                }} />}>

                </Card.Title>

                <Card.Content>
                  <FlatList
                    data={(this.state.images ? this.state.images.filter(i => i.item.imgType == 0) : [])}
                    renderItem={renderImage} />
                </Card.Content>

              </Card>

              <Card style={{ marginTop: 15 }}>
                <Card.Title title="Foto Depois" right={(props) => <IconButton {...props} icon="camera" onPress={() => {
                  this.navigation.navigate('NativeCameraScreen', { imageType: 1 });
                }} />}>

                </Card.Title>

                <Card.Content>
                  <FlatList
                    data={(this.state.images ? this.state.images.filter(i => i.item.imgType == 1) : [])}
                    renderItem={renderImage} />
                </Card.Content>

              </Card>


              <Card style={{ marginTop: 15, marginBottom: 80 }}>
                <Card.Title title="Foto RAT" right={(props) => <IconButton {...props} icon="camera" onPress={() => {
                  this.navigation.navigate('NativeCameraScreen', { imageType: 2 });
                }} />}>

                </Card.Title>

                <Card.Content>
                  <FlatList
                    data={(this.state.images ? this.state.images.filter(i => i.item.imgType == 2) : [])}
                    renderItem={renderImage} />
                </Card.Content>

              </Card>

            </View>

          </ScrollView>



        </View>


        <FAB
          style={base.fab}
          icon="content-save-move-outline"
          theme={{
            colors: {
              accent: this.colors.primary,
            },
          }}

          onPress={() => {
            saveItem();
            this.navigation.dispatch(CommonActions.goBack());
          }}
        />



        <ActivityIndicator animating={this.state.isLoading} color={Colors.amber700} />


      </SafeAreaView>
    )

  }



}



export default CollectModalComp;





const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});




const ImageContainner = styled.div`
  background: rgb(216 216 216 / 71%);
  border: #b6b6b6 1px solid;
  border-radius: 5px;
  padding: 25px;
  box-sizing: border-box;
  
  
`;

const ImagePreview = styled.div<{ image: string | null }>`

  height: 90px;
  max-height: 90px;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 450px) {
    height: 90px;
  }
`;


const FullScreenImagePreview = styled.div<{ image: string | null }>`
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  background-color: black;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;