import { NavigationContainer, Theme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';

import * as React from 'react';

import ModalScreen from '../screens/ModalScreen';


import MenuIcon from './MenuIcon';
import MenuContent from './MenuContent';
import { AuthContext } from '../context/PreferencesContext';
import LoginPage from '../screens/LoginScreen';
import OsPage from '../screens/OsScreen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CollectImageScreen from '../screens/CollectImageScreen';
import CameraScreen from '../screens/CameraScreen';
import NativeCameraScreen from '../screens/NativeCameraScreen';
import CollectMd from '../screens/CollectMd';

declare type Props = {
  theme?: Theme;
};

export default function Navigation({ ...props }: Props) {
  return (
    <NavigationContainer
      theme={props.theme as Theme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();


function RootNavigator() {

  const [userInfo, setUserData] = React.useState(undefined);

  //Function to change theme
  const setUser = React.useCallback((data) => {
    return setUserData(data);
  }, [userInfo]);

  const preferences = React.useMemo(
    () => ({ setUser, userInfo }), [setUser, userInfo]
  );

  React.useEffect(() => {
    AsyncStorage.getItem('@user').then(resp => {
      console.log(resp);
      setUserData(JSON.parse(resp));
    })
  }, []);


  return (
    <AuthContext.Provider value={preferences}>
      {userInfo ?
        <StackNavigator />
        :
        <LogOutNavigator />
      }
    </AuthContext.Provider>
  );

}

function StackNavigator() {
  return (

    <Stack.Navigator>

      <Stack.Group>
        <Stack.Screen
          name="Root"
          component={DrawerNavigator}
          options={{ headerShown: false }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="OsDetaisModal" component={ModalScreen} />
        <Stack.Screen name="CollectMd" component={CollectMd} options={{ title: 'Coleta de Imagens', headerShown: false }} />


        <Stack.Screen name="OsPageModal" component={OsPage} options={{ title: 'Buscar O.S.', headerShown: false }} />
        <Stack.Screen name="CameraScreen" component={CameraScreen} options={{ title: 'Capturar Imagem' }} />
        <Stack.Screen name="NativeCameraScreen" component={NativeCameraScreen} options={{ title: 'Capturar Imagem' }} />
      </Stack.Group>

    </Stack.Navigator>

  );
}

function DrawerNavigator() {

  return (
    <Drawer.Navigator screenOptions={{ headerShown: true, headerLeft: () => <MenuIcon /> }} drawerContent={(props) => <MenuContent {...props} />}>

      <Drawer.Screen name="CollectImagePage" component={CollectImageScreen} options={{ title: 'Atendimentos', headerShown: false }} />

      <Drawer.Screen name="OsPage" component={OsPage} options={{ title: 'Ordens de Serviço', headerShown: false }} />

    

      {/* <Drawer.Screen name="Article" component={Article} /> */}
    </Drawer.Navigator>
  );

}

function LogOutNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={LoginPage} options={{ headerShown: false }} />

    </Stack.Navigator>
  );
}


