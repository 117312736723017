
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Pressable, SafeAreaView, ScrollView, FlatList, Platform } from 'react-native';
import { ActivityIndicator, Appbar, Button, Caption, Card, Colors, Divider, FAB, List, Paragraph, TextInput, Title } from 'react-native-paper';
import base from '../constants/base';
import { CombinedDarkTheme, CombinedDefaultTheme } from '../constants/Theme';
import { AtendimentoLocal } from '../models/models';
import moment from 'moment';
import { useIndexedDB } from 'react-indexed-db';
import { AntDesign, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import api from '../services/data-service';
import { AuthContext } from '../context/PreferencesContext';
import ApiConfig from '../services/shared';
const MORE_ICON = Platform.OS === 'ios' ? 'dots-horizontal' : 'dots-vertical';
import FlashMessage, { showMessage } from "react-native-flash-message";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)



export default function CollectImageScreen({ navigation }) {


  const { userInfo } = React.useContext(AuthContext);

  const [isThemeDark] = React.useState(true);
  let theme = isThemeDark ? CombinedDarkTheme : CombinedDefaultTheme;
  const { colors } = useTheme();

  const { getAll, getByIndex, add, update } = useIndexedDB('collect');


  const [data, setData] = useState({ collects: [] as any[] });
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilterCode] = useState({ cod: '' });
  const [timer, setTimer] = useState(null);



  React.useLayoutEffect(() => {
    navigation.setOptions({

      headerRight: () => (

        <View style={{ justifyContent: 'flex-end' }}>
          <Pressable onPress={() => {
            console.log('check-all..');
          }}>


            <AntDesign name="reload1" size={24} style={{ marginRight: 15, color: colors.text }} />

          </Pressable>


          <Pressable onPress={() => {
            console.log('check-all..');
          }}>


            <Feather name="check-circle" size={24} style={{ marginRight: 15, color: colors.text }} />


          </Pressable>
        </View>

      ),
    });
  }, [navigation]);




  React.useEffect(() => {

    const unsubscribe = navigation.addListener('focus', () => {
      console.log('CollectImageScreen Focused ...');
      fetchData();
    });

    const fetchData = async () => {
      setIsLoading(true);

      getAll().then(resp => {
        setData({ collects: resp });
        setIsLoading(false);
      });


    };

    //fetchData();

    return unsubscribe;

  }, [filter, navigation]);





  function updateStrFind(change) {

    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setFilterCode({ cod: change });
      }, 800)
    );
  }


  async function validateItens() {

    setIsLoading(true);

    const result = await api.get(`/api/BuscarOrdensAbertas?idTecnico=${userInfo.idUsuario}&paraCheckList=false`).then();
    if (result.status == 200 && result.data) {
      var atendimentos = data.collects.filter(i => !i.item.idOS);

      atendimentos.forEach(atItem => {

        var osItem = result.data.find(i => i.cod.indexOf(atItem.item.nroOS) > -1);
        if (osItem) {
          let item = atItem.item as AtendimentoLocal;

          item.idUnidade = osItem.idUnidade;
          item.idOS = osItem.idOs;
          item.nroOS = osItem.cod;
          item.nomeCliente = osItem.nomeCliente;
          item.equipamento = osItem.equipamento;
          item.info = osItem.info;

          update({ id: atItem.id, item: item });
        }
      });
    }

    setIsLoading(false);

    setIsLoading(true);
    getAll().then(resp => {
      setData({ collects: resp });
      setIsLoading(false);
    });

  }


  async function confirmSendItens() {
    let resp = await MySwal.fire({
      title: 'Enviar Imagens?',
      html: 'Confirma o envio das imagens para o servidor?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sim, Enviar!',
      denyButtonText: `Não`,
    }).then();

    if (resp.isConfirmed) {
      enviarItens();    
    } else if (resp.isDenied) {
      
    }
  }

  async function enviarItens() {


    setIsLoading(true);
    const images = await useIndexedDB('images').getAll().then();
    const atItens = await getAll().then();
    setData({ collects: atItens });

    setIsLoading(false);


    var atendimentosValidados = data.collects.filter(i => i.item.validado === true);

    atendimentosValidados.forEach(atItem => {
      let item = atItem.item as AtendimentoLocal;
      let imgList = images.filter(i => i.collectId == atItem.id);

      var imgLength = imgList.length;
      var totalValidated = 0;


      imgList.forEach(async imgData => {

        if (!imgData.item.sendDate) {

          var cmdData = {
            IdEmpresa: ApiConfig.guidUrl,
            IdUnidade: item.idUnidade,
            IdUsuario: userInfo.idUsuario,
            ImageId: imgData.item.id,
            OrdemId: item.idOS,
            DataHora: item.dataEmissao,
            Tipo: imgData.item.imgType,
            Descricao: imgData.item.subject
          };


          var bodyFormData = new FormData();
          bodyFormData.append('base64Image', imgData.item.base64Image);
          bodyFormData.append('cmdData', JSON.stringify(cmdData));

          setIsLoading(true);
          var response: any;

          try {
            response = await api.request({
              url: 'api/enviarImagens',
              method: "post",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            }).then()
          } catch (error) {
            throwError(error);
          }

          setIsLoading(false);

          if (response?.data?.success) {
            imgData.item.sendDate = moment().toLocaleString();
            await useIndexedDB('images').update(imgData);
            totalValidated++;
            if (totalValidated >= imgLength) {
              item.enviado = true;
              update({ id: atItem.id, item: item });
              getAll().then(resp => {
                setData({ collects: resp });
              });

            }
          } else {


          }



        } else {
          totalValidated++;
          if (totalValidated >= imgLength) {
            item.enviado = true;
            update({ id: atItem.id, item: item });
            getAll().then(resp => {
              setData({ collects: resp });
            });

          }
        }

      });


    });



  }

  async function confirmClearItens() {
    let resp = await MySwal.fire({
      title: 'Limpar Dados?',
      html: 'Este procedimento vai excluir do seu dispositivo todos os atendimentos que já foram concluídos!<br><br> Confirma a limpeza dos atendimentos concluídos?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sim, Limpar!',
      denyButtonText: `Não`,
    }).then();

    if (resp.isConfirmed) {
      limparItens();    
    } else if (resp.isDenied) {
      
    }
  }

  async function limparItens() {

    setIsLoading(true);
    const images = await useIndexedDB('images').getAll().then();
    const atItens = await getAll().then();
    setData({ collects: atItens });

    var atendimentosEnviados = data.collects.filter(i => i.item.enviado === true);
    atendimentosEnviados.forEach(async atItem => {
      let item = atItem.item as AtendimentoLocal;
      let imgList = images.filter(i => i.collectId == atItem.id);


      setIsLoading(true);
      var responseValidate: any;

      try {

        responseValidate = await api.request({
          url: 'api/validarImagens',
          method: "post",

          data: {
            images: imgList.map(function (img) {
              return {
                idEmpresa: ApiConfig.guidUrl,
                idUnidade: item.idUnidade,
                idUsuario: userInfo.idUsuario,
                imageId: img.item.id
              }
            })
          },
        }).then();


        if (responseValidate?.data?.success) {


          imgList.forEach(async imgItem => {
            await useIndexedDB('images').deleteRecord(imgItem.id).then();
          });

          await useIndexedDB('collect').deleteRecord(atItem.id).then();
          getAll().then(resp => {
            setData({ collects: resp });
          });


        } else {

          //Nem todas as imagens foram salvas no servidor, não escluir atendimento e atulizar status da imagem

          debugger

          item.enviado = false;
          await update({ id: atItem.id, item: item }).then();

          if (responseValidate.data.images) {

            responseValidate.data.images.forEach(async imgValResp => {
              if (imgValResp.saved == false) {

                var imgError = imgList.find(i => i.item.id == imgValResp.imageId);

                if (imgError) {
                  imgError.item.sendDate = null;
                  await useIndexedDB('images').update({ id: imgError.id, collectId: imgError.collectId, item: imgError.item }).then();
                }




              }

            });

          }

          getAll().then(resp => {
            setData({ collects: resp });
          });

        }


      } catch (error) {
        throwError(error);
      }








    });


  }


  const throwError = (error) => {
    // Error 😨
    if (error.response) {
      console.log('error.response.data', error.response.data);

      if (error.response?.data?.errors) {
        let errorList = error.response?.data?.errors;
        let errMessage = '';
        errorList.forEach(err => {
          errMessage += err.message + '\n'
        });
        showMessage({
          message: errMessage,
          type: "danger",
          autoHide: false
        });

      }

    } else {
      showMessage({
        message: 'Falha ao enviar imagem!',
        type: "danger"
      });
    }
    console.log(error);
  }


  const Item = ({ data }) => {

    const Info = data.item.info ? JSON.parse(data.item.info) : [];

    return (
      <Pressable
        onPress={() => navigation.navigate('CollectMd', { collectId: data.id })}
        style={({ pressed }) => ({
          opacity: pressed ? 0.5 : 1,
        })}>

        <List.Item
          title={'O.S. ' + data.item.nroOS}
          description={() =>
            <div>
              <Caption style={{ fontSize: 10, fontWeight: 'bold' }}>{data.item.nomeCliente}</Caption>
              <br></br>
              <Caption style={{fontSize: 10}}>{data.item.equipamento}</Caption>

              <FlatList
                data={Info}
                renderItem={({ item }) => (

                  <Caption style={{fontSize: 10}}><span><b>{item.Key}:</b> {item.Value}</span></Caption>
                )}
              />
             



            </div>
          }
          left={props => (
            <List.Icon {...props} icon={() => (
              <MaterialCommunityIcons
                name={(data.item.validado ? 'database-check' : 'database')}
                style={{ color: data.item.enviado ? colors.primary : colors.text }}
                size={24} />)} />
          )}

          right={props => (
            <Caption style={{ fontSize: 8, color: colors.primary }}>
              {
                data.item.validado
                  ?
                  <span>Validado</span>
                  : null
              }

              {
                data.item.enviado
                  ?
                  <span> / Enviado</span>
                  : null
              }

            </Caption>
          )}


        />

        <Divider></Divider>


      </Pressable>)

  }

  const renderItem = ({ item }) => (
    <Item data={item} />
  );




  return (

    <SafeAreaView style={base.container}>

      <Appbar.Header>

        <Appbar.Action icon="hamburger" onPress={() => {
          navigation.toggleDrawer();
        }} />

        {/* <Appbar.BackAction icon="hamburger" onPress={() => { }} /> */}

        <Appbar.Content title="" subtitle={'Atendimentos'} />

        <Appbar.Action icon="trash-can-outline" onPress={() => {
          confirmClearItens();
        }} />


        <Appbar.Action icon="reload" onPress={() => {
          validateItens();
        }} />
        <Appbar.Action icon="checkbox-marked-circle-outline" onPress={() => {

          setIsLoading(true);
          setTimeout(() => {


            confirmSendItens();

          }, 500);
        }} />
      </Appbar.Header>


      <View style={base.bodyContainner}>
        <ScrollView >

          <FlatList
            data={data.collects}
            renderItem={renderItem}
            keyExtractor={item => item.id}
          />

        </ScrollView>
      </View>


      <ActivityIndicator animating={isLoading} color={Colors.amber700} style={{ position: 'absolute', bottom: 10, left: 10 }} />

      <FlashMessage position='bottom' />

      <FAB
        style={base.fab}
        icon="plus"
        theme={{
          colors: {
            accent: colors.primary,
          },
        }}
        onPress={() => {

          navigation.navigate('CollectMd', { collectId: null, addMode: true });

        }}
      />
    </SafeAreaView>

    // <View style={styles.container}>
    //   <Text >Atendimentos</Text>
    //   <TouchableOpacity onPress={() => navigation.replace('Root')} style={styles.link}>
    //     <Text style={styles.linkText}>Go to home screen!</Text>
    //   </TouchableOpacity>



    // </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  }
});
