
import axios from "axios";

const api = axios.create({
    baseURL: "https://api.v1.dotneterp.com.br:8050",
    //baseURL: "http://localhost:5001",
    headers: {
        "Access-Control-Allow-Origin": "*",
        //'Authorization':  `` ,
      },
});

api.interceptors.request.use(config => {

    const access_token = localStorage.getItem("@access_token");
    if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;

}, async function (error) {
    //   const access_token = localStorage.getItem("@access_token");
    //   if (error.response.status === 401 && access_token) {
    //     const response = await refreshToken(error);
    //     return response;
    //   }
    return Promise.reject(error);
});



// async function refreshToken(error) {
//     return new Promise((resolve, reject) => {
//       try {
//         const refresh_token = localStorage.getItem("@refresh_token");
//         const header = {
//           "Content-Type": "application/json",
//           Authorization: process.env.AUTHORIZATION,
//         };
//         const parameters = {
//           method: "POST",
//           headers: header,
//         };
//         const body = {
//           grant_type: "refresh_token",
//           refresh_token,
//         };
//         axios
//           .post(
//             process.env.API_URL + "/refreshtoken",
//             body,
//             parameters
//           )
//           .then(async (res) => {
//             localStorage.setItem("@access_token", res.data.access_token);
//             localStorage.setItem("@refresh_token", res.data.refresh_token);
//             // Fazer algo caso seja feito o refresh token
//             return resolve(res);
//           })
//           .catch((err) => {
//             // Fazer algo caso não seja feito o refresh token
//             return reject(error);
//           });
//       } catch (err) {
//         return reject(err);
//       }
//     });
//   };


export default api;



export const  dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab]);
    return bb;
}


