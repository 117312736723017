import React from 'react';

export const PreferencesContext = React.createContext({
  toggleTheme: () => {},
  isThemeDark: false,
});


export const AuthContext = React.createContext({
  setUser: (user) => {},
  userInfo: undefined
});