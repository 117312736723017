import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import {
  Card,
  Title,
  Paragraph,
  List,
  Provider as PaperProvider,
} from 'react-native-paper';
import React, { useEffect } from 'react';
import { CombinedDarkTheme, CombinedDefaultTheme } from './constants/Theme';
import { PreferencesContext } from './context/PreferencesContext';
import { NavigationContainer, Theme, useFocusEffect } from '@react-navigation/native';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert, BackHandler } from 'react-native';
import { DBConfig } from './services/DBConfig';
import { initDB } from 'react-indexed-db';

import 'moment/src/locale/pt';
 
initDB(DBConfig);


export default function App() {

   const backAction = () => {
    Alert.alert("Hold on!", "Are you sure you want to go back?", [
      {
        text: "Cancel",
        onPress: () => null,
        style: "cancel"
      },
      { text: "YES", onPress: () => BackHandler.exitApp() }
    ]);
    return true;
  };

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction);

    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction);
  }, []);

  

  const isLoadingComplete = useCachedResources();
  const [isThemeDark, setIsThemeDark] = React.useState(true);
  let theme = isThemeDark ? CombinedDarkTheme : CombinedDefaultTheme;


  //Function to change theme
  const toggleTheme = React.useCallback(() => {
    setIsThemeDark(!isThemeDark);
    AsyncStorage.setItem('@isThemeDark', (!isThemeDark) ? 'true' : 'false');
    window.location.reload();
  }, [isThemeDark]);

  const preferences = React.useMemo(
    () => ({ toggleTheme, isThemeDark }), [toggleTheme, isThemeDark]
  );

  React.useEffect(() => {

    AsyncStorage.getItem('@isThemeDark').then(resp => {
      setIsThemeDark(resp === 'true' ? true : false);
    })

  }, []);

  
  if (!isLoadingComplete) {
    return null;
  } else {
    return (

      <PreferencesContext.Provider value={preferences}>
        <PaperProvider theme={theme}>

          <Navigation theme={theme} />

          {/* <NavigationContainer theme={theme}>
          <Stack.Navigator initialRouteName="Home">
            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="Details" component={DetailsScreen} />
          </Stack.Navigator>
        </NavigationContainer> */}
        </PaperProvider>
      </PreferencesContext.Provider>

      // <SafeAreaProvider>
      //   <Navigation colorScheme={colorScheme} />
      //   <StatusBar />
      // </SafeAreaProvider>
    );
  }
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();