import { useEffect, useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import styled from 'styled-components';
import { Button, Caption, Card, Text, Divider, FAB, List, RadioButton, TextInput, Title } from 'react-native-paper';
import base from '../constants/base';
import Resizer from 'react-image-file-resizer';
import { ImageData, ImageDataType } from '../models/models';
import { CommonActions } from '@react-navigation/native';
import { v4 as uuidv4 } from 'uuid';

import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';



export default function NativeCameraScreen({ route, navigation }) {

  const [imgSrc, setImgSrc] = useState('');
  const imgData = new ImageData();
  imgData.imgType = route.params.imageType as ImageDataType;

  useEffect(() => {

    (async () => {

    })();

  }, []);


  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.files) {
      if (event.target.files.length !== 0) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          var imgData = reader.result.toString();
          console.log(imgData);
          setImgSrc(imgData);
        });
        reader.readAsDataURL(file);

        // const newUrl = URL.createObjectURL(file);
        // console.log(file);
        // setSource(newUrl);
      }
    }
  };

  const takePhoto = async () => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      // width: 1280,
      // height: 1280,
      // preserveAspectRatio: true, 
      quality: 100
    });
    
    //var base64Data = await base64FromPath(cameraPhoto.base64String);

    const response = await fetch(cameraPhoto.dataUrl);
    const blob = await response.blob();
  
    Resizer.imageFileResizer(
      blob,
      1280,
      1280,
      'JPEG',
      100,
      0,
      uri => {
        console.log(uri)
        var img = uri.toString();
        setImgSrc(img);

      },
      'base64'
    );



  };

 

  const fileChangedHandler = (event) => {
    var fileInput = false
    if (event.target.files[0]) {
      fileInput = true
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        1280,
        1280,
        'JPEG',
        95,
        0,
        uri => {
          console.log(uri)
          var img = uri.toString();
          setImgSrc(img);

        },
        'base64'
      );
    }
  }



  return (
    <SafeAreaView style={base.container}>

      {imgSrc ?
        <Card style={{ flex: 1, justifyContent: 'center' }}>
          <img src={imgSrc} alt={"snap"}></img>
        </Card> : null}

      <div style={{ flex: 1, justifyContent: 'center' }}>

        <Button icon="camera" mode="contained" style={{ margin: 20 }} onPress={() => {
          takePhoto();
        }}>
          Abrir Camera
        </Button>

        {/* <input id="icon-button-file" type="file" name="file" accept="image/*" onChange={fileChangedHandler} style={{ display: 'none' }} />
        <label htmlFor="icon-button-file">
          <Button icon="camera" mode="contained" style={{ margin: 20 }}>
            Carregar Imagens
          </Button>
        </label> */}

      </div>


      <FAB
        style={base.fab}
        icon="check-bold"
        onPress={async () => {
          if (imgSrc) {
            imgData.base64Image = imgSrc;
            imgData.id = uuidv4();
            sessionStorage.setItem('@currentImage', JSON.stringify(imgData));
            navigation.dispatch(CommonActions.goBack());
          }

        }}
      />

    </SafeAreaView>

  );

}




const ImagePreview = styled.div<{ image: string | null }>`
  width: 120px;
  height: 120px;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 400px) {
    width: 50px;
    height: 120px;
  }
`;