import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { FlatList, SafeAreaView, ScrollView, Text, TouchableOpacity, View, StyleSheet, Pressable, DeviceEventEmitter } from 'react-native';
import { ActivityIndicator, Appbar, Button, Caption, Card, Colors, Divider, List, Paragraph, TextInput, Title } from 'react-native-paper';
import { TextInput as Input } from 'react-native-paper';


import api from '../services/data-service';
import base from '../constants/base';
import { FontAwesome } from '@expo/vector-icons';
import { AuthContext } from '../context/PreferencesContext';
import { Axios } from 'axios';



interface IOsPageProps {

}

interface OrderModel {
    idOs: number,
    cod: string,
}



function OsPage({ route, navigation }) {

    const findMode = route?.params?.findMode;

    const { userInfo } = React.useContext(AuthContext);

    const [filter, setFilterCode] = useState({ cod: '' as string, idUsuario: userInfo.idUsuario });
    const [data, setData] = useState({ orders: [] as OrderModel[] });
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [timer, setTimer] = useState(null);

    function updateStrFind(change) {

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                setFilterCode({ cod: change, idUsuario: userInfo.idUsuario });
            }, 800)
        );
    }


    React.useEffect(() => {

        const fetchData = async () => {

            setIsError(false);
            setIsLoading(true);

            try {
                const result = await api.get(`/api/BuscarOrdensAbertas?idTecnico=${filter.idUsuario}&cod=${filter.cod}&take=50&paraCheckList=true`).then();
                setData({ orders: result.data });
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };


        fetchData();

    }, [filter]);


    const Item = ({ item }) => {

        const Info = item.info ? JSON.parse(item.info) : [];

        return (
            <Pressable
                onPress={() => {
                    if (findMode === true) {
                        sessionStorage.setItem('@selectedOS', JSON.stringify(item));
                        navigation.goBack();
                    } else {
                        navigation.navigate('OsDetaisModal', { order: item });
                    }

                }}
                style={({ pressed }) => ({
                    opacity: pressed ? 0.5 : 1,
                })}>

                <List.Item
                    title={'O.S. ' + item.cod}
                    description={() =>
                        <div>
                            <Caption style={{ fontWeight: 'bold' }}>{item.nomeCliente}</Caption>
                            <br></br>

                            <Caption style={{fontSize: 10}}>{item.endereco}</Caption>
                            <br></br>

                            <Caption style={{ fontWeight: 'bold' }}>{item.equipamento}</Caption>

                            <FlatList
                                data={Info}
                                renderItem={({ item }) => (

                                    <Caption><span><b>{item.Key}:</b> {item.Value}</span></Caption>
                                )}
                            />
                          
                            


                        </div>
                    }

                />

                <Divider></Divider>

                {/* <Card key={item.idOs} style={base.cardItem}>
                <Card.Content>
                    <Title>{item.cod}</Title>
                    <Paragraph style={{ fontWeight: 'bold' }}>{item.nomeCliente}</Paragraph>
                    <Paragraph>{item.equipamento}</Paragraph>
                </Card.Content>


            </Card> */}


            </Pressable>)



    };

    const renderItem = ({ item }) => (
        <Item item={item} />
    );


    return (

        <SafeAreaView style={base.container}>

            <Appbar.Header>

                {findMode ?
                    <Appbar.BackAction onPress={() => {
                        navigation.goBack();
                    }} />
                    :
                    <Appbar.Action icon="hamburger" onPress={() => {
                        navigation.toggleDrawer();
                    }} />
                }


                <Appbar.Content title="" subtitle={(findMode ? 'Buscar O.S.' : 'Ordens de Serviço')} />
                {/* <Appbar.Action icon="reload" onPress={() => { }} />
                <Appbar.Action icon="checkbox-marked-circle-outline" onPress={() => { }} /> */}
            </Appbar.Header>

            <View style={base.headerpage}>

                <Input style={base.input} mode='flat'
                    placeholder="Buscar O.S."
                    right={<TextInput.Icon name="file-find" />}
                    onChangeText={(value) => updateStrFind(value)}
                />

                {/* <Button icon="file-find" mode="contained" onPress={() => setFilterCode({ cod: filter.cod, idUsuario: userInfo.idUsuario })}>
                    Buscar
                </Button> */}

            </View>

            <Divider></Divider>

            <View style={base.bodyContainner}>
                <ScrollView >

                    <FlatList
                        data={data.orders}
                        renderItem={renderItem}
                        keyExtractor={item => item.idOs}
                    />

                </ScrollView>
            </View>

            <ActivityIndicator animating={isLoading} color={Colors.amber700} />

        </SafeAreaView>

    );
};


export default OsPage;
