import React from 'react';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
} from '@react-navigation/drawer';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Switch, TouchableRipple, useTheme, Text, Caption, Avatar, Title, Drawer } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthContext, PreferencesContext } from '../context/PreferencesContext';
import Animated from 'react-native-reanimated';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const MenuContent: React.FunctionComponent<DrawerContentComponentProps> = (
  props
) => {

  const theme = useTheme();
  const { setUser, userInfo } = React.useContext(AuthContext);
  const { toggleTheme, isThemeDark } = React.useContext(PreferencesContext);


  return (

    <DrawerContentScrollView {...props}>
      <Animated.View>

        <Drawer.Section style={styles.drawerSection}>

          <View style={styles.userInfoSection}>
            <TouchableOpacity
              onPress={() => {
                props.navigation.toggleDrawer();
              }}
            >
              <Avatar.Image style={{ backgroundColor: '#cfcece' }}
                source={require('../assets/images/icon.png')}
                size={80}
              />
            </TouchableOpacity>
            <Title style={styles.title}>{userInfo.name}</Title>
            <Caption style={styles.caption}>{userInfo.email}</Caption>

            <Caption style={styles.drawerSigout}
              onPress={async () => {
                //Linking.openURL('https://mywebsite.com/help')
                await AsyncStorage.removeItem('@access_token');
                await AsyncStorage.removeItem('@user');
                setUser(undefined);
                //window.location.reload();
              }}
            >Sair</Caption>
          </View>


        </Drawer.Section>


        <Drawer.Section style={styles.drawerSection}>

          {/* <DrawerItemList {...props} /> */}

          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="comment-search"
                color={color}
                size={size}
              />
            )}
            label="Ordens Pendentes"
            onPress={() => { props.navigation.navigate('OsPage') }}
          />


          <DrawerItem style={{ marginBottom: 30 }}
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="camera-image"
                color={color}
                size={size}
              />
            )}
            label="Atendimento"
            onPress={() => { props.navigation.navigate('CollectImagePage') }}
          />


        </Drawer.Section>


        <Drawer.Section title="Preferências">
          <TouchableRipple onPress={toggleTheme}>
            <View style={styles.preference}>
              <Text>Modo Escuro</Text>
              <View pointerEvents="none">
                <Switch value={isThemeDark} />
              </View>
            </View>
          </TouchableRipple>

        </Drawer.Section>

        <Caption style={{position:'absolute', top: 4, left: 10, fontSize: 8 }}>v1.0.7</Caption>


      </Animated.View>
    </DrawerContentScrollView>



  );
};

export default MenuContent;



const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
  userInfoSection: {
    alignItems: 'center',
    paddingBottom: 20
  },
  title: {
    marginTop: 20,
    fontWeight: 'bold',
  },
  drawerSigout: {
    // position: 'absolute',
    // top: 4,
    // right: 4,
    height: 15,
  },
  caption: {
    fontSize: 14,
    lineHeight: 14,
  },
  row: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
  },
  paragraph: {
    fontWeight: 'bold',
    marginRight: 3,
  },
  drawerSection: {
    marginTop: 15,
  },
  preference: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});