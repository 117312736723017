import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { CommonActions } from '@react-navigation/native';
import React from 'react';
import { Pressable } from 'react-native';
import { useTheme } from 'react-native-paper';
import { AuthContext } from '../context/PreferencesContext';
import CollectModalComp from './CollectModalComp';


export default function CollectMd({ props, route, navigation, theme }) {

  const { colors } = useTheme();
  const { userInfo } = React.useContext(AuthContext);



  React.useLayoutEffect(() => {
    navigation.setOptions({

      headerLeft: () => (
        <Pressable onPress={() => {
          navigation.dispatch(CommonActions.goBack());
        }}>

          <Ionicons name="arrow-back" size={24} style={{ marginLeft: 15, color:colors.text }} />



        </Pressable>
      ),


    });
  }, [navigation]);



  return (

  
    <CollectModalComp {...props}
    colors={colors}
      collectId={route?.params.collectId}
      userInfo={userInfo}
      navigation={navigation}></CollectModalComp>
  )
}